import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  IonItem,
  IonContent,
  IonRadio,
  IonRadioGroup,
  IonList,
  IonLabel,
  IonPage,
  IonButton,
  IonInput,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { checkmark } from "ionicons/icons";
import {
  UrlListarCarrinho,
  UrlFinalizarPedido,
  UrlFormasDePagamento,
  urlContaUsuario,
  tokenUsuario,
} from "../../../utils/constants";
import MenuSide from "../../../components/usuarios/MenuSide";
import { ModalSucessoPagamento } from "./SucessoPagamento";
import { loadingRequest, alertNotification } from "../../../utils/functions";
import LoadingScreen from "../../../components/global/LoadingScreen";
import axios from "axios";
import Lottie from "react-lottie";
import animationData from "./images/location.json";

export default function CarrinhoUsuario() {
  const navigate = useNavigate();
  const [formasDePagamento, setFormasDePagamento] = useState([]);
  const [totalPedido, setTotalPedido] = useState(0);
  const [modalSucesso, setModalSucesso] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState(null);
  const [cadastroEndereco, setCadastroEndereco] = useState(false);

  const [nomeCartao, setNomeCartao] = useState("");
  const [numeroCartao, setNumeroCartao] = useState("");
  const [mesAnoCartao, setMesAnoCartao] = useState("");
  const [ccvCartao, setCcvCartao] = useState("");

  const [enderecosUsuario, setEnderecosUsuario] = useState([]);
  const [endereco, setEndereco] = useState("");

  const [loading, setLoading] = useState(true);

  const [pixGerado, setPixGerado] = useState(false);
  const [urlPix, setUrlPix] = useState("");

  const consultaConta = async () => {
    try {
      const response = await axios.post(urlContaUsuario, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const formatExpirationDate = (input) => {
    let formatted = input.replace(/\D/g, "");

    if (formatted.length > 2) {
      formatted = formatted.substring(0, 2) + "/" + formatted.substring(2);
    }
    return formatted.slice(0, 7);
  };

  const validarNumeroCartao = (numero) => {
    numero = numero.replace(/\D/g, "");

    let soma = 0;
    let deveDobrar = false;

    for (let i = numero.length - 1; i >= 0; i--) {
      let digito = parseInt(numero.charAt(i), 10);

      if (deveDobrar) {
        digito *= 2;
        if (digito > 9) {
          digito -= 9;
        }
      }

      soma += digito;
      deveDobrar = !deveDobrar;
    }

    return soma % 10 === 0;
  };

  const consultarItens = async () => {
    try {
      const response = await axios.post(UrlListarCarrinho, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const consultarFormasDePagamento = async () => {
    try {
      const response = await axios.post(UrlFormasDePagamento, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const finalizarPedido = async () => {
    const { dismiss } = loadingRequest("Aguarde...", 60000);
    try {
      const response = await axios.post(UrlFinalizarPedido, {
        token: tokenUsuario,
        forma_pagamento: formaPagamento,
        numeroCartao: numeroCartao,
        mesVencimentoCartao: mesAnoCartao.substring(0, 2),
        anoVencimentoCartao: mesAnoCartao.substring(3, 7),
        ccvCartao: ccvCartao,
        nomeCartao: nomeCartao,
        endereco: endereco,
      });
      let statusReponse = response.data.status;
      let mensagemReponse = response.data.mensagem;
      let urlReponse = response.data.url;
      if (statusReponse === 'finalizado') {
        if (urlReponse) {
          setUrlPix(urlReponse);
          setPixGerado(true);
        } else {
          setModalSucesso(true);
        }
      } else {
        alertNotification(
          "Atenção",
          mensagemReponse,
          "Ok",
          ()=> { return }
        );
      }
      dismiss();
    } catch (error) {
      dismiss();
      console.log(error);
    }
  };

  useEffect(() => {
    const run = async () => {
      const resposeConta = await consultaConta();
      setEnderecosUsuario(resposeConta.enderecos);
      const response = await consultarItens();
      const responseFormasPagamento = await consultarFormasDePagamento();
      setFormasDePagamento(responseFormasPagamento.formas_de_pagamento);
      setTotalPedido(response.total_soma);
      setLoading(false);
      if (resposeConta.enderecos.length === 0) {
        setCadastroEndereco(true);
      }

      for (let i = 0; i < resposeConta.enderecos.length; i++) {
        let itemE = resposeConta.enderecos[i];
        if (itemE.padrao === 1) {
          setEndereco(itemE.id);
        }
      }
    };
    run();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <IonPage>
      <IonContent>
        {cadastroEndereco ? (
          <>
            <div className="ion-text-center" style={{ marginTop: "30%" }}>
              <Lottie options={defaultOptions} height={150} width={150} />
            </div>
            <div className="ion-text-center ion-margin">
              <h5 style={{ fontWeight: 500 }}>
                Você não tem nenhum endereço cadastrado
              </h5>
              <p>Clique no botão abaixo para seguir com o cadastro</p>
              <br />
              <IonButton
                onclick={() => {
                  navigate({
                    pathname: "/usuarios/enderecos/cadastro",
                    search: createSearchParams({
                      pedido: 1,
                    }).toString(),
                  });
                }}
                color="danger"
                expand="block"
              >
                Cadastrar endereço
              </IonButton>
            </div>
          </>
        ) : (
          <>
            {!pixGerado ? (
              <>
                <IonItem>
                  <IonLabel>
                    <h3>Endereço de entrega</h3>
                  </IonLabel>
                </IonItem>
                <IonList>
                  <IonRadioGroup mode="md" value={endereco}>
                    {enderecosUsuario.map((enderecoE, index) => (
                      <IonItem key={index}>
                        <h6>
                          {enderecoE.rua} - {enderecoE.numero} -{" "}
                          {enderecoE.bairro} | {enderecoE.cidade}/
                          {enderecoE.estado}
                        </h6>
                        <IonRadio
                          slot="end"
                          value={enderecoE.id} // Corrigido de endereco.id para enderecoE.id
                          onClick={() => {
                            setEndereco(enderecoE.id);
                          }}
                        ></IonRadio>
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                </IonList>
                <br />
                <IonItem>
                  <IonLabel>
                    <h3>Forma de pagamento</h3>
                  </IonLabel>
                </IonItem>
                <IonList>
                  <IonRadioGroup value={formaPagamento}>
                    {formasDePagamento.map((pagamento, index) => (
                      <IonItem key={index}>
                        <IonLabel>{pagamento.nome}</IonLabel>
                        <IonRadio
                          slot="end"
                          value={pagamento.id}
                          onClick={() => setFormaPagamento(pagamento.id)}
                        ></IonRadio>
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                </IonList>

                {loading ? (
                  <LoadingScreen />
                ) : (
                  <>
                    {formaPagamento === 11 ? (
                      <div>
                        <IonItem>
                          <IonLabel position="stacked">
                            Número do cartão
                          </IonLabel>
                          <IonInput
                            onIonChange={(event) => {
                              setNumeroCartao(event.detail.value);
                            }}
                            type="tel"
                            value={numeroCartao}
                            maxlength={16}
                            placeholder="Número do cartão"
                          ></IonInput>
                        </IonItem>
                        {!validarNumeroCartao(numeroCartao) &&
                        numeroCartao.length === 16 ? (
                          <IonLabel
                            color={"danger"}
                            style={{ marginLeft: "15px", fontSize: "12px" }}
                          >
                            Número do cartão de crédito inválido
                          </IonLabel>
                        ) : null}
                        <IonRow>
                          <IonCol size="5">
                            <IonItem>
                              <IonLabel position="stacked">Mês/Ano</IonLabel>
                              <IonInput
                                onIonChange={(event) => {
                                  setMesAnoCartao(
                                    formatExpirationDate(event.detail.value)
                                  );
                                }}
                                type="tel"
                                maxlength={7}
                                placeholder="Mês/Ano"
                                value={mesAnoCartao}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                          <IonCol size="3">
                            <IonItem>
                              <IonLabel position="stacked">CCV</IonLabel>
                              <IonInput
                                onIonChange={(event) => {
                                  setCcvCartao(event.detail.value);
                                }}
                                value={ccvCartao}
                                type="password"
                                maxlength={3}
                                placeholder="CCV"
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonItem>
                          <IonLabel position="stacked">
                            Nome impresso no cartão
                          </IonLabel>
                          <IonInput
                            onIonChange={(event) => {
                              setNomeCartao(event.detail.value);
                            }}
                            type="text"
                            value={nomeCartao}
                            placeholder="Nome impresso no cartão"
                          ></IonInput>
                        </IonItem>
                      </div>
                    ) : null}

                    <IonItem lines="none">
                      <IonLabel>Totais</IonLabel>
                      <IonLabel slot="end">R$ {totalPedido}</IonLabel>
                    </IonItem>

                    {formaPagamento ? (
                      <div className="ion-margin-horizontal">
                        <IonButton
                          onClick={() => finalizarPedido()}
                          color={"danger"}
                          expand="block"
                        >
                          Finalizar pedido
                          <IonIcon icon={checkmark} slot="end" />
                        </IonButton>
                      </div>
                    ) : null}
                    {modalSucesso ? <ModalSucessoPagamento /> : null}
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  className="ion-text-center"
                  style={{ marginTop: "30%" }}
                ></div>
                <div className="ion-text-center ion-margin">
                  <h5 style={{ fontWeight: 500 }}>
                    Pix gerado com sucesso, clique no botão abaixo para pagar!
                  </h5>
                  <br />
                  <a href={urlPix}>
                    <IonButton color="danger" expand="block">
                      Pagar pedido
                    </IonButton>
                  </a>
                </div>
              </>
            )}
          </>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
