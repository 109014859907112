/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IonContent,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonLabel,
  IonIcon,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import {
  UrlInfoProduto,
  urlImagensProdutos,
  UrlAdicionarCarrinho,
  tokenUsuario,
} from "../../utils/constants";
import { cartOutline } from "ionicons/icons";
import LoadingScreen from "../../components/global/LoadingScreen";
import { alertNotification, loadingRequest } from "../../utils/functions";
import Lottie from "react-lottie";
import animationHorarioAtendimento from "./images/foraHorarioAtendimento.json";
import axios from "axios";
import dayjs from "dayjs";

export const ModalProduto = ({ fecharModal, idProduto, sucessoCarrinho }) => {
  const navigate = useNavigate();
  const [produto, setProduto] = useState([]);
  const [ingredientes, setIngredientes] = useState([]);
  const [adicionais, setAdicionais] = useState([]);
  const [valorTotal, setValorTotal] = useState(0);
  const [valorTotalIngredientes, setValorTotalIngredientes] = useState(0);
  const [qntProduto, setQntProduto] = useState(1);
  const [acordionSelecionado, setAcordionSelecionado] = useState("monte");
  const [loading, setLoading] = useState(true);
  const [horarioAtendimento, setHorarioAtendimento] = useState(true);
  const [horariosAtendimento, setHorariosAtendimento] = useState([]);

  const consultarProduto = async () => {
    try {
      let numeroDia = dayjs().day();
      if (numeroDia === 0) {
        numeroDia = 7;
      }
      const response = await axios.post(UrlInfoProduto, {
        token: tokenUsuario,
        id: idProduto,
        dia: numeroDia,
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (idProduto) {
      const runProduto = async () => {
        const response = await consultarProduto();
        setProduto(response.produto);
        if (response.ingredientes) {
          setIngredientes(response.ingredientes);
        }
        setAdicionais(response.adicionais);
        setHorarioAtendimento(response.horario_atendimento);
        setHorariosAtendimento(response.horarios_atendimento);
      };
      runProduto();
    }
  }, [idProduto]);

  const adicionarProduto = () => {
    setQntProduto(qntProduto + 1);
  };

  const subtrairProduto = () => {
    if (qntProduto > 0) {
      setQntProduto(qntProduto - 1);
    }
  };

  const buscarIngredientes = (array) => {
    return array.filter(
      (item) => item.hasOwnProperty("quantidade") && item.quantidade > 0
    );
  };

  const adicionarIngrediente = (id) => {
    console.log(ingredientes);
    setIngredientes((currentIngredientes) =>
      currentIngredientes.map((ingrediente) =>
        ingrediente.id === id
          ? { ...ingrediente, quantidade: (ingrediente.quantidade || 0) + 1 }
          : ingrediente
      )
    );
  };

  const subtrairIngrediente = (id) => {
    setIngredientes((currentIngredientes) =>
      currentIngredientes.map((ingrediente) =>
        ingrediente.id === id
          ? {
              ...ingrediente,
              quantidade: Math.max(0, (ingrediente.quantidade || 0) - 1),
            }
          : ingrediente
      )
    );
  };

  const adicionarAdicional = (id) => {
    setAdicionais((currentIngredientes) =>
      currentIngredientes.map((adicional) =>
        adicional.id === id
          ? { ...adicional, quantidade: (adicional.quantidade || 0) + 1 }
          : adicional
      )
    );
  };

  const subtrairAdicional = (id) => {
    setAdicionais((currentIngredientes) =>
      currentIngredientes.map((adicional) =>
        adicional.id === id
          ? {
              ...adicional,
              quantidade: Math.max(0, (adicional.quantidade || 0) - 1),
            }
          : adicional
      )
    );
  };

  useEffect(() => {}, [ingredientes]);

  useEffect(() => {
    const total = produto.valor * qntProduto;
    setValorTotal(total);
    let totalValue = 0;
    for (let i = 0; i < adicionais.length; i++) {
      let item = adicionais[i];
      if (item.quantidade && item.quantidade > 0) {
        let value = item.quantidade * item.valor;
        totalValue = value + totalValue;
      }
    }
    setValorTotalIngredientes(totalValue);
  }, [valorTotal, produto, qntProduto, adicionais]);

  const verificaLimiteIngrediente = (valor, limite) => {
    if (valor === limite) {
      const funcao = () => {
        return;
      };
      let tituloD = "Atenção";
      let dscD = `Você atingiu o limite para esse item que é de ${limite}`;
      alertNotification(tituloD, dscD, "OK", funcao);
      return true;
    }
  };

  const adicionarAoCarrinho = async () => {
    const { dismiss } = loadingRequest("Aguarde...", 60000);
    try {
      const response = await axios.post(UrlAdicionarCarrinho, {
        token: tokenUsuario,
        produto_id: idProduto,
        qnt: qntProduto,
        ingredientes: buscarIngredientes(adicionais),
        ingredientes_produtos: buscarIngredientes(ingredientes),
      });
      let status = response.data.status;
      if (status === "nao_logado") {
        const funcao = () => {
          navigate({ pathname: "/usuarios/login" });
        };
        alertNotification(
          "Atenção",
          "Para continuar, você deve prosseguir com o login no aplicativo",
          "Entrar",
          funcao
        );
      } else if (status === "adicionado_carrinho") {
        sucessoCarrinho(true);
        fecharModal(false);
      }
      dismiss();
    } catch (error) {
      dismiss();
      console.log(error);
    }
  };

  const optionsAnimationForaHAtendimento = {
    loop: true,
    autoplay: true,
    animationData: animationHorarioAtendimento,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <IonModal
        className="modalTermos"
        isOpen={true}
        mode="ios"
        backdropDismiss="false"
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onclick={() => fecharModal(false)}>Voltar</IonButton>
            </IonButtons>
            <IonTitle>{produto.nome}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {loading ? (
            <LoadingScreen />
          ) : (
            <>
              {horarioAtendimento ? (
                <>
                  <img
                    src={`${urlImagensProdutos}${produto.imagem ? produto.imagem : 'padrao.png'}`}
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                  <div>
                    <IonSegment value={acordionSelecionado}>
                      <IonSegmentButton
                        value="monte"
                        onclick={() => setAcordionSelecionado("monte")}
                      >
                        <IonLabel>Monte sua marmita</IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton
                        value="adicionais"
                        onclick={() => setAcordionSelecionado("adicionais")}
                      >
                        <IonLabel>Adicionais</IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                    {acordionSelecionado === "monte" ? (
                      <>
                        <div className="ion-padding" slot="content">
                          {ingredientes.map((ingrediente, index) => (
                            <div
                              style={{ borderBottom: "1px solid #dee2e6" }}
                              key={index}
                            >
                              <div style={{ marginBottom: 10, marginTop: 10 }}>
                                <IonLabel>
                                  <h6 style={{ fontWeight: 500 }}>
                                    {ingrediente.nome}
                                  </h6>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "-8%",
                                      }}
                                    >
                                      <div>
                                        <IonButton
                                          color="medium"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          onclick={() => {
                                            subtrairIngrediente(ingrediente.id);
                                          }}
                                        >
                                          -
                                        </IonButton>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        &nbsp;&nbsp;
                                        {ingrediente.quantidade ? (
                                          <span>{ingrediente.quantidade}</span>
                                        ) : (
                                          <span>{0}</span>
                                        )}
                                        &nbsp;&nbsp;
                                      </div>
                                      <div>
                                        <IonButton
                                          color="light"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          //disabled={ingrediente.quantidade === ingrediente.limite ? true : false}
                                          onclick={() => {
                                            if (
                                              verificaLimiteIngrediente(
                                                ingrediente.quantidade,
                                                ingrediente.limite
                                              )
                                            ) {
                                              return;
                                            }
                                            adicionarIngrediente(
                                              ingrediente.id
                                            );
                                          }}
                                        >
                                          +
                                        </IonButton>
                                      </div>
                                    </div>
                                  </div>
                                </IonLabel>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : null}

                    {acordionSelecionado === "adicionais" ? (
                      <>
                        <div className="ion-padding" slot="content">
                          {adicionais.map((adicional, index) => (
                            <div
                              style={{ borderBottom: "1px solid #dee2e6" }}
                              key={index}
                            >
                              <div style={{ marginBottom: 10, marginTop: 10 }}>
                                <IonLabel>
                                  <h6 style={{ fontWeight: 500 }}>
                                    {adicional.nome} {adicional.limite}
                                  </h6>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "-8%",
                                      }}
                                    >
                                      <div>
                                        <IonButton
                                          color="medium"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          onclick={() => {
                                            subtrairAdicional(adicional.id);
                                          }}
                                        >
                                          -
                                        </IonButton>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        &nbsp;&nbsp;
                                        {adicional.quantidade ? (
                                          <span>{adicional.quantidade}</span>
                                        ) : (
                                          <span>{0}</span>
                                        )}
                                        &nbsp;&nbsp;
                                      </div>
                                      <div>
                                        <IonButton
                                          color="light"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          //disabled={ingrediente.quantidade === ingrediente.limite ? true : false}
                                          onclick={() => {
                                            if (
                                              verificaLimiteIngrediente(
                                                adicional.quantidade,
                                                adicional.limite
                                              )
                                            ) {
                                              return;
                                            }
                                            adicionarAdicional(adicional.id);
                                          }}
                                        >
                                          +
                                        </IonButton>
                                      </div>
                                    </div>
                                  </div>
                                </IonLabel>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                  <br />
                  <br />
                  <br />
                  <div>
                    <div
                      style={{
                        position: "fixed",
                        bottom: 0,
                        paddingBottom: 5,
                        width: "100%",
                        background: "#ffffff",
                      }}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 7,
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>
                              <IonButton
                                color="light"
                                style={{ width: "35px", height: "35px" }}
                                onclick={() => subtrairProduto()}
                              >
                                -
                              </IonButton>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              &nbsp;&nbsp;<span>{qntProduto}</span>&nbsp;&nbsp;
                            </div>
                            <div>
                              <IonButton
                                color="light"
                                style={{ width: "35px", height: "35px" }}
                                onclick={() => adicionarProduto()}
                              >
                                +
                              </IonButton>
                            </div>
                          </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-6">
                          <IonButton
                            onclick={() => adicionarAoCarrinho()}
                            color="danger"
                            expand="full"
                          >
                            Adicionar R$ {valorTotal + valorTotalIngredientes}
                            <IonIcon slot="end" icon={cartOutline}></IonIcon>
                          </IonButton>
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="ion-text-center" style={{ marginTop: "30%" }}>
                    <Lottie options={optionsAnimationForaHAtendimento} height={150} width={150} />
                  </div>
                  <div className="ion-text-center ion-margin">
                    <h5 style={{ fontWeight: 500 }}>
                      Não é permitido fazer pedidos fora do horário de atendimento.
                    </h5>
                    <h6 style={{ fontWeight: 400 }}>
                      Horário: {horariosAtendimento.horario_inicial_1} ás {horariosAtendimento.horario_final_1}
                    </h6>
                    <br />
                    <IonButton
                      onclick={() => {
                        fecharModal(false)
                      }}
                      color="danger"
                      expand="block"
                    >
                      Ok
                    </IonButton>
                  </div>
                </>
              )}
            </>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};
