import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonList,
  IonItem,
  IonContent,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import { menuOutline, closeOutline, chevronBack } from "ionicons/icons";
function HeaderApp() {
  const location = useLocation();
  const pathname = location.pathname;
  const partesDoCaminho = pathname.split("/");
  const primeiroCaminho = partesDoCaminho[1];
  const segundoCaminho = partesDoCaminho[2];

  const navigate = useNavigate();
  const menu = useRef();

  const returnNamePath = (rota) => {
    if (rota === "pedidos") {
      return "Pedidos";
    } else if (rota === "pedido") {
      return "Pedido";
    } else if (rota === "conta") {
      return "Perfil";
    }  else {
      return "Rota não localizada";
    }
  };

  return (
    <>
      <IonHeader mode="ios">
        <IonToolbar>
          {primeiroCaminho === "empresas" ? (
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  menu.current.open();
                }}
              >
                <IonIcon
                  style={{ fontSize: 20, color: "#000000" }}
                  icon={menuOutline}
                ></IonIcon>
              </IonButton>
            </IonButtons>
          ) : null}
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <IonIcon
                style={{ fontSize: 20, color: "#000000" }}
                icon={chevronBack}
              ></IonIcon>
            </IonButton>
          </IonButtons>

          <IonTitle>{returnNamePath(segundoCaminho)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonMenu ref={menu} side="start" contentId="menu">
        <IonHeader mode="md">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  menu.current.setOpen(false);
                }}
              >
                <IonIcon style={{ fontSize: 20 }} icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/inicio" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-home-line"></i> &nbsp;Início
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/calendario" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-calendar-check-line"></i>{" "}
                &nbsp;Calendário/Agenda
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/dashboard" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-pie-chart-line"></i> &nbsp;Dashboard
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/agendar" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-add-line"></i> &nbsp; Agendar horário
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/agendamentos" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-calendar-line"></i> &nbsp; Agendamentos
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/agendamentos-dia" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-calendar-event-fill"></i> &nbsp; Agendamentos
                do dia
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/calendario-semana" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-calendar-todo-line"></i> &nbsp; Agendamentos da
                semana
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/dias_nao_atendidos" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-calendar-close-fill"></i> &nbsp; Dias não
                atendidos
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({
                  pathname: "/empresas/configuracoes_horario_atendimento",
                });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-calendar-schedule-line"></i> &nbsp; Horário de
                atendimento
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/servicos" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-store-line"></i> &nbsp; Serviços
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/clientes" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-user-line"></i> &nbsp; Clientes
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/conta" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-shield-user-line"></i> &nbsp; Perfil
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/configuracoes" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-settings-line"></i> &nbsp; Configurações
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                navigate({ pathname: "/empresas/suporte" });
              }}
              button={true}
            >
              <IonLabel>
                <i className="ri-question-line"></i> &nbsp; Suporte/Ajuda
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
}
export default HeaderApp;
