import React, { useState, useRef } from "react";
import { urlCodigoVerificacaoUsuario, modoDev } from "../../../utils/constants";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "universal-cookie";
import Lottie from "react-lottie";
import animationData from "./images/animationCodigoVerificacao.json";
import { toastNotificacao } from "../../../utils/functions";

export default function CodigoVerificacaoUsuario() {

  const [codigo1, setCodigo1] = useState("");
  const [codigo2, setCodigo2] = useState("");
  const [codigo3, setCodigo3] = useState("");
  const [codigo4, setCodigo4] = useState("");
  const [codigo5, setCodigo5] = useState("");
  const [codigo6, setCodigo6] = useState("");

  const [loading, setLoading] = useState(false);

  const codigoRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleCodigoChange = (e, index) => {
    let value = e.target.value;

    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    switch (index) {
      case 0:
        setCodigo1(value);
        break;
      case 1:
        setCodigo2(value);
        break;
      case 2:
        setCodigo3(value);
        break;
      case 3:
        setCodigo4(value);
        break;
      case 4:
        setCodigo5(value);
        break;
      case 5:
        setCodigo6(value);
        break;
      default:
        break;
    }

    if (value.length === 1 && index < 5) {
      codigoRefs[index + 1].current.focus();
    } else {
      if (value !== "") {
        verificarCodigo(value);
      }
    }
  };

  const verificarCodigo = async (codigoSeis) => {
    const cookies = new Cookies();
    const codigoEnviar = `${codigo1}${codigo2}${codigo3}${codigo4}${codigo5}${codigoSeis}`;
    try {
      const response = await axios.post(urlCodigoVerificacaoUsuario, {
        valor: codigoEnviar,
      });
      if (response.data.status === 1) {
        const umAnoEmMillisegundos = 365 * 24 * 60 * 60 * 1000;
        let cookieOptions;
        if (modoDev) {
          cookieOptions = {
            secure: false,
            httpOnly: false,
            path: "/",
            expires: new Date(new Date().getTime() + umAnoEmMillisegundos),
          };
        } else {
          cookieOptions = {
            secure: true,
            httpOnly: false,
            path: "/",
            expires: new Date(new Date().getTime() + umAnoEmMillisegundos),
          };
        }
        cookies.set(
          "token_usuario_marmita_express",
          response.data.token,
          cookieOptions
        );
        setLoading(false);
        toastNotificacao(
          "success",
          "alert-outline",
          "Login efetuado com sucesso. Aguarde...",
          10000,
          "Ok"
        );
        window.location.href = "/";
      } else {
        setLoading(false);
        toastNotificacao(
          "danger",
          "alert-outline",
          "Código inválido ou expirado.",
          10000,
          "Ok"
        );
      }
    } catch (error) {
      return null;
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="ion-margin">
      <div className="row">
        <div className="col-sm-4"></div>
        <div className="col-sm-4">
          <div className="ion-margin ion-text-center">
            <Lottie options={defaultOptions} height={150} width={150} />

            <h1
              className="titulo"
              style={{
                fontSize: 25,
                fontWeight: 900,
                marginTop: 0,
              }}
            >
              Código de verificação
            </h1>
            <br />
            <h6>
              Informe o código de 6 números enviado no seu Whatsapp/E-mail. (No
              caso do e-mail, verifique se está indo para da caixa de SPAM.)
            </h6>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            {codigoRefs.map((ref, index) => (
              <div style={{ width: "100%", margin: 0, padding: 0 }} key={index}>
                <input
                  className="inputCodigoVerificacao"
                  style={{
                    margin: 0,
                    width: 50,
                    border: "solid #333333 1px",
                    borderRadius: 11,
                  }}
                  value={
                    index === 0
                      ? codigo1
                      : index === 1
                      ? codigo2
                      : index === 2
                      ? codigo3
                      : index === 3
                      ? codigo4
                      : index === 4
                      ? codigo5
                      : codigo6
                  }
                  onChange={(e) => handleCodigoChange(e, index)}
                  type="tel"
                  ref={ref}
                />
              </div>
            ))}
          </div>
          <br /> <br />
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "20%",
              }}
            ></div>
          ) : (
            <span></span>
          )}
        </div>
        <div className="col-sm-4"></div>
      </div>
    </div>
  );
}
