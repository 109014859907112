import React, { useState, useEffect, useRef } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import {
  urlContaUsuario,
  urlEnviarCodigoPerfilUsuario,
  urlAtualziarCodigoPerfilUsuario,
  tokenUsuario,
} from "../../../utils/constants";
import MenuSide from "../../../components/usuarios/MenuSide";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonToolbar,
  IonModal,
  IonHeader,
  IonTitle,
  IonButtons,
  IonBadge,
  IonIcon,
} from "@ionic/react";
import { logInOutline, addOutline, refreshOutline } from "ionicons/icons";

import HeaderApp from "../../../components/global/HeaderItem";
import LoadingScreen from "../../../components/global/LoadingScreen";
import Lottie from "react-lottie";
import animationData from "./images/animationCodigoVerificacao.json";
import imgContaNaoLogado from "./images/conta_nao_logado.json";
import { toastNotificacao } from "../../../utils/functions";
import moment from "moment";
import "moment/locale/pt-br";
moment().locale("pt-br");

export default function ContaUsuario() {
  const [info, setInfo] = useState([]);
  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [codigo, setCodigo] = useState("");
  const [ddd, setDDD] = useState("");
  const [telefone, setTelefone] = useState("");
  const [loadingPerfil, setLoadingPerfil] = useState(true);

  const [modalCodigo, setModalCodigo] = useState(false);

  const consultaLista = async () => {
    try {
      const response = await axios.post(urlContaUsuario, {
        token: tokenUsuario,
      });
      setLoadingPerfil(false);
      return response.data;
    } catch (error) {
      setLoadingPerfil(false);
    }
  };

  const consultaCodigoAtualizarPerfil = async () => {
    try {
      const response = await axios.post(urlEnviarCodigoPerfilUsuario, {
        token: tokenUsuario,
      });
      if (response) {
        setModalCodigo(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const consultarItens = async () => {
    const responseConta = await consultaLista();
    setNome(responseConta.usuario.nome);
    setEmail(responseConta.usuario.email);
    setCodigo(responseConta.usuario.codigo);
    setDDD(responseConta.usuario.ddd);
    setTelefone(responseConta.usuario.telefone);
    setInfo(responseConta);
  };

  useEffect(() => {
    consultarItens();
  }, []);

  const [codigo1, setCodigo1] = useState("");
  const [codigo2, setCodigo2] = useState("");
  const [codigo3, setCodigo3] = useState("");
  const [codigo4, setCodigo4] = useState("");
  const [codigo5, setCodigo5] = useState("");
  const [codigo6, setCodigo6] = useState("");

  const [loading, setLoading] = useState(false);

  const codigoRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleCodigoChange = (e, index) => {
    let value = e.target.value;

    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    switch (index) {
      case 0:
        setCodigo1(value);
        break;
      case 1:
        setCodigo2(value);
        break;
      case 2:
        setCodigo3(value);
        break;
      case 3:
        setCodigo4(value);
        break;
      case 4:
        setCodigo5(value);
        break;
      case 5:
        setCodigo6(value);
        break;
      default:
        break;
    }

    if (value.length === 1 && index < 5) {
      codigoRefs[index + 1].current.focus();
    } else {
      if (value !== "") {
        verificarCodigo(value);
      }
    }
  };

  const verificarCodigo = async (codigoSeis) => {
    const codigoEnviar = `${codigo1}${codigo2}${codigo3}${codigo4}${codigo5}${codigoSeis}`;
    try {
      const response = await axios.post(urlAtualziarCodigoPerfilUsuario, {
        valor: codigoEnviar,
        nome: nome,
        email: email,
        codigo: codigo,
        ddd: ddd,
        telefone: Number(`9${telefone}`),
        token: tokenUsuario,
      });
      if (response.data.status === 1) {
        setModalCodigo(false);
        setLoading(false);
        toastNotificacao(
          "success",
          "alert-outline",
          "Informações atualizadas com sucesso",
          10000,
          "Ok"
        );
      } else {
        setLoading(false);
        toastNotificacao(
          "danger",
          "alert-outline",
          "Código inválido ou expirado.",
          10000,
          "Ok"
        );
      }
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptionsImgContaNaoLogado = {
    loop: true,
    autoplay: true,
    animationData: imgContaNaoLogado,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent className="contentAgendamento">
        {loadingPerfil ? (
          <LoadingScreen />
        ) : (
          <>
            {info.usuario ? (
              <>
                <div>
                  <IonItem>
                    <IonLabel position="stacked">Nome</IonLabel>
                    <IonInput
                      className="nome"
                      placeholder="Nome"
                      type="text"
                      onIonChange={(e) => setNome(e.detail.value)}
                      value={nome}
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">E-mail</IonLabel>
                    <IonInput
                      className="usuario"
                      placeholder="E-mail"
                      type="email"
                      onIonChange={(e) => setEmail(e.detail.value)}
                      value={email}
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">Código</IonLabel>
                    <IonInput
                      className="codigo"
                      placeholder="DDD"
                      type="tel"
                      onIonChange={(e) => setCodigo(e.detail.value)}
                      value={codigo}
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">DDD</IonLabel>
                    <IonInput
                      className="ddd"
                      placeholder="DDD"
                      type="tel"
                      onIonChange={(e) => setDDD(e.detail.value)}
                      value={ddd}
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">Telefone</IonLabel>
                    <IonInput
                      className="telefone"
                      placeholder="Telefone"
                      type="tel"
                      onIonChange={(e) => setTelefone(e.detail.value)}
                      value={telefone}
                    />
                  </IonItem>

                  <div className="ion-margin">
                    <IonButton
                      color="danger"
                      expand="full"
                      onClick={consultaCodigoAtualizarPerfil}
                    >
                      Atualizar perfil &nbsp;{" "}
                      <IonIcon icon={refreshOutline} slot="end" />
                    </IonButton>
                  </div>
                </div>

                <IonItem>
                  <IonLabel>
                    <h3>Endereço de entrega</h3>
                  </IonLabel>
                </IonItem>
                {info.enderecos.map((enderecoE, index) => (
                  <IonItem
                    style={{ display: "flex", flexDirection: "column" }}
                    key={index}
                    button
                    onclick={() => {
                      navigate({
                        pathname: "/usuarios/enderecos/cadastro",
                        search: createSearchParams({
                          id: enderecoE.id,
                        }).toString(),
                      });
                    }}
                  >
                    <IonLabel>
                      {enderecoE.padrao === 1 ? (
                        <IonBadge color="danger">Padrão para entregas</IonBadge>
                      ) : null}
                      <h6 style={{ fontSize: "12px", fontWeight: 400 }}>
                        Rua: {enderecoE.rua} - {enderecoE.numero}
                      </h6>

                      <h6 style={{ fontSize: "12px", fontWeight: 400 }}>
                        Complemento: {enderecoE.complemento}
                      </h6>

                      <h6 style={{ fontSize: "12px", fontWeight: 400 }}>
                        Bairro: {enderecoE.bairro}
                      </h6>

                      <h6 style={{ fontSize: "12px", fontWeight: 400 }}>
                        Cidade/Estado: {enderecoE.cidade} / {enderecoE.estado}
                      </h6>
                    </IonLabel>
                  </IonItem>
                ))}
                 <div className="ion-margin">
                    <IonButton
                      color="medium"
                      expand="full"
                      onClick={()=>{
                        navigate({ pathname: "/usuarios/enderecos/cadastro" });
                      }}
                    >
                     Adicionar endereço  <IonIcon icon={addOutline} slot="end" />
                    </IonButton>
                  </div>
              </>
            ) : (
              <>
                <div className="ion-text-center" style={{ marginTop: "30%" }}>
                  <Lottie
                    options={defaultOptionsImgContaNaoLogado}
                    height={150}
                    width={150}
                  />
                </div>
                <div className="ion-text-center ion-margin">
                  <h5 style={{ fontWeight: 500 }}>
                    Você não está logado. Clique no botao abaixo para entrar na
                    sua conta
                  </h5>
                  <br />
                  <IonButton
                    onclick={() => {
                      navigate({ pathname: "/usuarios/login" });
                    }}
                    color="danger"
                    expand="block"
                  >
                    Entrar <IonIcon icon={logInOutline} slot="end" />
                  </IonButton>
                </div>
              </>
            )}
          </>
        )}
      </IonContent>
      {modalCodigo ? (
        <IonModal isOpen={true} mode="ios" backdropDismiss="false">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onclick={() => setModalCodigo(false)}>
                  Fechar
                </IonButton>
              </IonButtons>
              <IonTitle>Código de verificação</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <div className="ion-margin">
              <div className="row">
                <div className="col-sm-4"></div>
                <div className="col-sm-4">
                  <div className="ion-margin ion-text-center">
                    <Lottie options={defaultOptions} height={150} width={150} />

                    <h1
                      className="titulo"
                      style={{
                        fontSize: 25,
                        fontWeight: 900,
                        marginTop: 0,
                      }}
                    >
                      Código de verificação
                    </h1>
                    <br />
                    <h6>
                      Informe o código de 6 números enviado no seu
                      Whatsapp/E-mail. (No caso do e-mail, verifique se está
                      indo para da caixa de SPAM.)
                    </h6>
                  </div>
                  <br />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {codigoRefs.map((ref, index) => (
                      <div
                        style={{ width: "100%", margin: 0, padding: 0 }}
                        key={index}
                      >
                        <input
                          className="inputCodigoVerificacao"
                          style={{
                            margin: 0,
                            width: 50,
                            border: "solid #333333 1px",
                            borderRadius: 11,
                          }}
                          value={
                            index === 0
                              ? codigo1
                              : index === 1
                              ? codigo2
                              : index === 2
                              ? codigo3
                              : index === 3
                              ? codigo4
                              : index === 4
                              ? codigo5
                              : codigo6
                          }
                          onChange={(e) => handleCodigoChange(e, index)}
                          type="tel"
                          ref={ref}
                        />
                      </div>
                    ))}
                  </div>
                  <br /> <br />
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        marginTop: "20%",
                      }}
                    ></div>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="col-sm-4"></div>
              </div>
            </div>
          </IonContent>
        </IonModal>
      ) : null}

      <MenuSide />
    </IonPage>
  );
}
