import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const apiUrl = process.env.REACT_APP_API_BASE_URL;

//Usuários
export const urlImagensProdutos = apiUrl + 'usuarios/produtos/imagens/';
export const urlLoginUsuario = apiUrl + 'usuarios/entrar/adicionar';
export const urlCadastrarUsuario = apiUrl + 'usuarios/cadastrar/adicionar';
export const urlCodigoVerificacaoUsuario = apiUrl + 'usuarios/codigo_verificacao/listar';
export const urlInfoContaUsuario = apiUrl + 'usuarios/consultas/conta';

export const urlInfoPedido = apiUrl + 'usuarios/pedidos/get_id';

export const urlListarProdutos = apiUrl + 'usuarios/produtos/listar';
export const UrlInfoProduto = apiUrl + 'usuarios/produtos/get_id';
export const UrlAdicionarCarrinho = apiUrl + 'usuarios/pedidos/adicionar';
export const UrlListarCarrinho = apiUrl + 'usuarios/pedidos/carrinho';
export const UrlAdicionarRemoverItemCarrinho = apiUrl + 'usuarios/pedidos/carrinho_adicionar_remover_item';
export const UrlFormasDePagamento = apiUrl + 'usuarios/pedidos/formas_pagamento';
export const UrlFinalizarPedido = apiUrl + 'usuarios/pedidos/finalizar_pedido';

export const urlContaUsuario = apiUrl + 'usuarios/consultas/conta';
export const urlEnviarCodigoPerfilUsuario = apiUrl + 'usuarios/inicio/listar';
export const urlAtualziarCodigoPerfilUsuario = apiUrl + 'usuarios/inicio/editar';

export const UrlCadastrarEnderecoUsuario = apiUrl + 'usuarios/enderecos/adicionar';
export const UrlEditarEnderecoUsuario = apiUrl + 'usuarios/enderecos/editar';
export const UrlInfoEnderecoUsuario = apiUrl + 'usuarios/enderecos/get_id';

//Token
export let tokenUsuario = getCookie("token_usuario_marmita_express");
export let modoDev = false;
if(apiUrl.includes('dev')){ 
   modoDev = true;
   tokenUsuario = cookies.get('token_usuario_marmita_express');
}

//Urls base
export const urlListar = (tipoUsuario, tipoTela) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/listar`;
}
export const urlAdicionar = (tipoUsuario, tipoTela) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/adicionar`;
}
export const urlInformacoes = (tipoUsuario, tipoTela, params) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/get_id?${params}`;
}
export const urlAlterar = (tipoUsuario, tipoTela) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/editar`;
}
export const urlApagar = (tipoUsuario, tipoTela) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/apagar`;
}

//Sessão do usuário

function getCookie(name) {
    let cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookiePair = cookieArray[i].split('=');
        if (name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}