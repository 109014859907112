import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import { urlListar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/usuarios/MenuSide";
import {
  IonList,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
  IonButton,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";
import StatusPedido from "../../../components/global/StatusAgendamento";
import LoadingScreen from "../../../components/global/LoadingScreen";
import moment from "moment";
import "moment/locale/pt-br";

moment().locale("pt-br");

export default function PedidoUsuario() {
  const navigate = useNavigate();

  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [pagina, setPagina] = useState(1);
  const [loading, setLoading] = useState(true);

  const [itens, setItens] = useState([]);
  const [total, setTotal] = useState(0);
  const location = useLocation();

  const consultaLista = async (url) => {
    try {
      const response = await axios.post(url + `?pagina=${pagina}`, {
        token: tokenUsuario,
        data_inicial: dataInicial,
        data_final: dataFinal,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const consultarItens = async () => {
    const pathname = location.pathname;

    const partesDoCaminho = pathname.split("/");
    const tipo = partesDoCaminho[1];
    const tela = partesDoCaminho[2];

    const url = urlListar(tipo, tela);
    const lista = await consultaLista(url);
    setItens((itensPrev) => [...itensPrev, ...lista.pedidos]);
    if(lista.pedidos.length > 0){
      setTotal(lista.total);
    } else {
      setTotal(0);
    }
   
  };

  useEffect(() => {
    consultarItens();
  }, [pagina, dataFinal]);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin-horizontal">
          <div className="row">
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">Data Inicial: </IonLabel>
                <IonInput
                  type="date"
                  onIonInput={(event) => {
                    event.preventDefault();
                    setDataInicial(event.target.value);
                  }}
                  value={dataInicial}
                />
              </IonItem>
            </div>
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">Data Inicial: </IonLabel>
                <IonInput
                  type="date"
                  onIonInput={(event) => {
                    event.preventDefault();
                    setDataFinal(event.target.value);
                  }}
                  value={dataFinal}
                />
              </IonItem>
            </div>
          </div>
        </div>

        {loading ? (
          <LoadingScreen />
        ) : (
          <div>
            {itens.length > 0 ? (
              <div>
                <div>
                  <IonList>
                    {itens.map((row, index) => (
                      <IonItem
                        onClick={() => {
                          navigate({
                            pathname: "/usuarios/pedido",
                            search: createSearchParams({
                              id: row.id,
                            }).toString(),
                          });
                        }}
                        key={index}
                      >
                        <IonLabel>
                          <h5
                            style={{
                              textTransform: "capitalize",
                              fontWeight: 700,
                            }}
                          >
                            {moment(row.data).format("DD/MM/YYYY HH:mm")}
                          </h5>
                          <h5>Nº: {row.id}</h5>
                          <h5>Valor: R$ {row.valor}</h5>
                          <h5>Forma de pagamento: {row.forma_pagamento}</h5>
                          <StatusPedido status={row.status} />
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </div>
                {total > 10 ?(
                   <div className="ion-margin">
                   <IonButton
                     expand="block"
                     color="danger"
                     onClick={() => {
                       setPagina((prevPagina) => prevPagina + 1);
                     }}
                   >
                     Ver mais
                   </IonButton>
                 </div>
                ):null}
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    marginTop: "30vh",
                    marginBottom: "30vh",
                  }}
                >
                  Sem pedidos!
                </div>
              </div>
            )}
          </div>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
