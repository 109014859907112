import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UrlCadastrarEnderecoUsuario,
  UrlEditarEnderecoUsuario,
  urlContaUsuario,
  UrlInfoEnderecoUsuario,
  tokenUsuario,
} from "../../../utils/constants";
import axios from "axios";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonContent,
  IonPage,
  IonToggle,
} from "@ionic/react";

import { toastNotificacao } from "../../../utils/functions";
import HeaderApp from "../../../components/global/HeaderItem";

export default function CadastroEnderecoUsuario() {
  const navigate = useNavigate();
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [cidade, setCidade] = useState("");
  const [complemento, setComplemento] = useState("");
  const [uf, setUf] = useState("");

  const [cadastroEnderecoPadrao, setCadastroEnderecoPadrao] = useState(false);
  const [enderecoPadrao, setEnderecoPadrao] = useState(true);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idPage = urlParams.get("id");

  const consultaConta = async () => {
    try {
      const response = await axios.post(urlContaUsuario, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  const consultaInfoEndereco = async (id) => {
    try {
      const response = await axios.post(UrlInfoEnderecoUsuario, {
        id: id,
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const cadastrarEndereco = async () => {
    try {
      const response = await axios.post(UrlCadastrarEnderecoUsuario, {
        cep: cep,
        rua: rua,
        numero: numero,
        bairro: bairro,
        cidade: cidade,
        uf: uf,
        token: tokenUsuario,
        padrao: enderecoPadrao,
        complemento: complemento,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      let statusResponse = response.data.status;

      if (statusResponse === "endereco_cadastrado") {
        toastNotificacao(
          "success",
          "alert-outline",
          "Endereço cadastrado com sucesso!",
          2000,
          "Ok"
        );
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const atualizarEndereco = async () => {
    try {
      const response = await axios.post(UrlEditarEnderecoUsuario, {
        id: idPage,
        cep: cep,
        rua: rua,
        numero: numero,
        bairro: bairro,
        cidade: cidade,
        uf: uf,
        token: tokenUsuario,
        padrao: enderecoPadrao,
        complemento: complemento,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      let statusResponse = response.data.status;

      if (statusResponse === "endereco_cadastrado") {
        toastNotificacao(
          "success",
          "alert-outline",
          "Endereço atualizado com sucesso!",
          2000,
          "Ok"
        );
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const consultarEndereco = async () => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${cep}/json/`,
        {}
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*const verificarCampos = async () => {
    if (!ddd && !numero && !cep && !email && !nome) {
      return;
    }
    if ((ddd.length !== null) & (ddd.length < 2)) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu DDD precisa ter 2 dígitos",
        10000,
        "Ok"
      );
    }

    if (ddd.length > 2) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu DDD precisa ter 2 dígitos",
        10000,
        "Ok"
      );
    }

    if (numero.length < 9) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu número precisa ter 9 dígitos",
        10000,
        "Ok"
      );
    }

    if (numero.length > 9) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu número precisa ter 9 dígitos",
        10000,
        "Ok"
      );
    }

    if (nome.length === 0) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Preencha o campo nome",
        10000,
        "Ok"
      );
    }

    if (email.length === 0) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Preencha o campo e-mail corretamente",
        10000,
        "Ok"
      );
    }

    if (!validarEmail(email)) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Preencha o campo e-mail corretamente",
        10000,
        "Ok"
      );
    }

    showActionSheet(
      "O número +" + cep + " " + ddd + numero + " está correto?",
      [
        {
          text: "Sim",
          handler: () => {
            cadastrarEndereco();
          },
        },
        {
          text: "Fechar",
          handler: () => {
            return;
          },
        },
      ]
    );
  }; */

  useEffect(() => {
    const run = async () => {
      const response = await consultarEndereco();
      setRua(response.logradouro);
      setBairro(response.bairro);
      setCidade(response.localidade);
      setUf(response.uf);
    };
    if (cep.length === 8) {
      run();
    }
  }, [cep]);

  useEffect(() => {
    const run = async () => {
      const response = await consultaConta();
      const enderecosUsuario = response.enderecos.length;
      if (enderecosUsuario > 0) {
        setCadastroEnderecoPadrao(true);
        setEnderecoPadrao(false);
      }
    };
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      const response = await consultaInfoEndereco(idPage);
      setCep(response.cep);
      setRua(response.rua);
      setNumero(response.numero);
      setBairro(response.bairro);
      setCidade(response.cidade);
      setUf(response.estado);
      setComplemento(response.complemento);
      if(response.padrao === 1){
        setEnderecoPadrao(true);
      } else {
        setEnderecoPadrao(false);
      }
    };
    if(idPage){
      setEnderecoPadrao(true);
      run();
    }
  }, [idPage]);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="row">
          <div className="col-sm-4"></div>
          <div className="col-sm-4">
            <div className="ion-margin centro" name="logarusuario">
              <div style={{ margin: "1%" }}></div>

              <h2 className="nomeDoApp"></h2>

              <IonText color="medium">
                <h5 className="IonText-center">
                  Preencha os campos abaixo para continuar
                </h5>
              </IonText>

              <div className="esquerda">
                <IonItem>
                  <IonLabel position="stacked">
                    CEP do endereço de entrega:{" "}
                  </IonLabel>
                  <IonInput
                    type="tel"
                    placeholder="CEP do endereço de entrega"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setCep(event.target.value);
                    }}
                    value={cep}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Rua: </IonLabel>
                  <IonInput
                    type="tel"
                    placeholder="Rua"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setRua(event.target.value);
                    }}
                    value={rua}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Número: </IonLabel>
                  <IonInput
                    type="tel"
                    placeholder="Número"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setNumero(event.target.value);
                    }}
                    value={numero}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Complemento: </IonLabel>
                  <IonInput
                    type="text"
                    placeholder="Complemento"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setComplemento(event.target.value);
                    }}
                    value={complemento}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Bairro: </IonLabel>
                  <IonInput
                    type="text"
                    placeholder="Bairro"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setBairro(event.target.value);
                    }}
                    value={bairro}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Cidade: </IonLabel>
                  <IonInput
                    type="text"
                    placeholder="Cidade"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setCidade(event.target.value);
                    }}
                    value={cidade}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">UF/Estado: </IonLabel>
                  <IonInput
                    type="text"
                    placeholder="UF/Estado"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setUf(event.target.value);
                    }}
                    value={uf}
                  />
                </IonItem>
              </div>

              {cadastroEnderecoPadrao ? (
                <>
                  <IonItem>
                    <IonLabel>Endereço padrão:</IonLabel>
                    <IonToggle
                      mode="ios"
                      checked={enderecoPadrao}
                      onIonChange={(e) => setEnderecoPadrao(e.detail.checked)}
                    />
                  </IonItem>
                </>
              ) : (
                <>
                  <IonItem>
                    <IonLabel>Endereço padrão:</IonLabel>
                    <IonToggle
                      mode="ios"
                      checked={enderecoPadrao}
                      disabled={true}
                    />
                  </IonItem>
                </>
              )}

              <IonButton
                onClick={() => {
                  if(idPage){
                    atualizarEndereco();
                  } else {
                    cadastrarEndereco();
                  }
                }}
                color="danger"
                expand="full"
                className=""
              >
                Confirmar &nbsp; <i className="ri-shield-check-line"></i>
              </IonButton>
            </div>
          </div>

          <div className="col-sm-4"></div>
        </div>
      </IonContent>
    </IonPage>
  );
}
