import React, { useState, useEffect } from "react";
import axios from "axios";
import { urlInfoPedido, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/usuarios/MenuSide";
import { IonItem, IonLabel, IonPage, IonContent } from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";
import StatusPedido from "../../../components/global/StatusAgendamento";
import LoadingScreen from "../../../components/global/LoadingScreen";
import moment from "moment";
import "moment/locale/pt-br";

moment().locale("pt-br");

export default function PedidoUsuario() {
  const [pedido, setPedido] = useState([]);
  const [itens, setItens] = useState([]);
  const [ingredientes, setIngredientes] = useState([]);
  const [adicionais, setAdicionais] = useState([]);
  const [loading, setLoading] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idPage = urlParams.get("id");

  const consultaPedido = async (id) => {
    try {
      const response = await axios.post(urlInfoPedido, {
        token: tokenUsuario,
        id: id,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const listaIngredientes = (ingredientes, produtoId) => {
    const ingredientesFiltrados = ingredientes.filter(
      (ingrediente) => ingrediente.item_id === produtoId
    );
    const nomesIngredientes = ingredientesFiltrados.map(
      (ingrediente) => ingrediente.nome_ingrediente
    );
    const lista = nomesIngredientes.join(", ");
    if (lista.length > 0) {
      return `(${lista})`;
    } else {
      return lista;
    }
  };

  useEffect(() => {
    const run = async () => {
      const response = await consultaPedido(idPage);
      setPedido(response.pedido);
      setItens(response.itens);
      setIngredientes(response.ingredientes);
      setAdicionais(response.adicionais);
    };
    if (idPage) {
      run();
    }
  }, [idPage]);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        {loading ? (
          <LoadingScreen />
        ) : (
          <div>
            <IonItem>
              <IonLabel>
                <h5
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                  }}
                >
                  {moment(pedido.data).format("DD/MM/YYYY HH:mm")}
                </h5>
                <h5>Nº: {pedido.id}</h5>
                <h5>Valor: R$ {pedido.valor}</h5>
                <h5>Forma de pagamento: {pedido.forma_pagamento}</h5>
                <StatusPedido status={pedido.status} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h3>Itens do pedido</h3>
              </IonLabel>
            </IonItem>
            {itens.map((row, index) => (
              <>
                <IonItem key={index}>
                  <IonLabel>
                    <h5>{row.nome_produto}</h5>
                    <h5>Quantidade:{row.qnt}</h5>
                    <h5>Valor: R$ {row.valor_item}</h5>
                    <h5>Total: {row.total}</h5>
                    <h6>{listaIngredientes(ingredientes, row.id)}</h6>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h4>Adicionais</h4>
                  </IonLabel>
                </IonItem>
                {adicionais
                  .filter((adicional) => adicional.item_id === row.id)
                  .map((adicionalItem, indexAdicional) => (
                    <>
                      <IonItem key={indexAdicional}>
                        <IonLabel>
                          <h5>{adicionalItem.nome_ingrediente}</h5>
                          <h5>Quantidade:{adicionalItem.qnt}</h5>
                          <h5>Valor: R$ {adicionalItem.valor}</h5>
                          <h5>Total: R$ {adicionalItem.total}</h5>
                        </IonLabel>
                      </IonItem>
                    </>
                  ))}
                <br />
              </>
            ))}
          </div>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
