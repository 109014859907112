import React from "react";
import { useLocation } from "react-router-dom";

import MenuSide from "../../components/usuarios/MenuSide";
import { IonIcon } from "@ionic/react";
import { locationOutline } from "ionicons/icons";

import HeaderApp from "../../components/global/HeaderItem";

export default function NotFound() {
  const location = useLocation();
  const pathname = location.pathname;
  const partesDoCaminho = pathname.split("/");
  const primeiroNomeDoCaminho = partesDoCaminho[1];

  return (
    <>
      <HeaderApp />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          marginTop: "40vh",
        }}
      >
        <IonIcon
          style={{ fontSize: 30, marginBottom: 10 }}
          icon={locationOutline}
        ></IonIcon>
        Rota não localizada
      </div>
      {primeiroNomeDoCaminho === "usuarios" ? (
        <MenuSide />
      ) :null}
    </>
  );
}
