import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonContent,
  IonThumbnail,
  IonLabel,
  IonPage,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { useNavigate } from "react-router-dom";
import {
  UrlListarCarrinho,
  urlImagensProdutos,
  UrlAdicionarRemoverItemCarrinho,
  tokenUsuario,
} from "../../../utils/constants";
import Lottie from "react-lottie";
import animationData from "./images/carrinhoVazio.json";
import MenuSide from "../../../components/usuarios/MenuSide";
import { alertNotificationMultiple } from "../../../utils/functions";
import axios from "axios";
import LoadingScreen from "../../../components/global/LoadingScreen";

export default function CarrinhoUsuario() {
  const navigate = useNavigate();

  const [itens, setItens] = useState([]);
  const [ingredientes, setIngredientes] = useState([]);
  const [adicionais, setAdicionais] = useState([]);
  const [totalPedido, setTotalPedido] = useState(0);
  const [loadingCarrinho, setLoadingCarrinho] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const consultarItens = async () => {
    try {
      const response = await axios.post(UrlListarCarrinho, {
        token: tokenUsuario,
      });
      setLoadingCarrinho(false);
      return response.data;
    } catch (error) {
      setLoadingCarrinho(false);
      console.log(error);
    }
  };

  const adicionarRemoverItem = async (id, tipo) => {
    try {
      const response = await axios.post(UrlAdicionarRemoverItemCarrinho, {
        token: tokenUsuario,
        id: id,
        tipo: tipo,
      });
      if (response.data) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const carregarItens = async () => {
    const response = await consultarItens();
    setItens(response.itens);
    setTotalPedido(response.total_soma);
  };

  const carregarAdicionarRemoverItem = async (id, tipo) => {
    adicionarRemoverItem(id, tipo);
    carregarItens();
    carregarItens();
  };

  const confirmarExclusaoItem = (id, tipo) => {
    const funcao = () => {
      return;
    };
    const funcao2 = () => {
      carregarAdicionarRemoverItem(id, tipo);
    };
    let titulo = "Atenção";
    let descricao = "Deseja remover esse item do carrinho?";
    let textoBotao = "Não";
    let textoBotao2 = "Sim";
    alertNotificationMultiple(
      titulo,
      descricao,
      textoBotao,
      funcao,
      textoBotao2,
      funcao2
    );
  };

  useEffect(() => {
    const run = async () => {
      const response = await consultarItens();
      setItens(response.itens);
      setIngredientes(response.ingredientes);
      setAdicionais(response.adicionais);
      setTotalPedido(response.total_soma);
    };
    run();
  }, []);

  const listaIngredientes = (ingredientes, produtoId) => {
    const ingredientesFiltrados = ingredientes.filter(
      (ingrediente) => ingrediente.item_id === produtoId
    );
    const nomesIngredientes = ingredientesFiltrados.map(
      (ingrediente) => ingrediente.nome_ingrediente
    );
    const lista = nomesIngredientes.join(", ");
    if (lista.length > 0) {
      return `(${lista})`;
    } else {
      return lista;
    }
  };

  return (
    <IonPage>
      <IonContent>
        {loadingCarrinho ? (
          <LoadingScreen />
        ) : (
          <>
            {totalPedido > 0 ? (
              <div>
                <div className="ion-margin">
                  <div className="row">
                    {itens ? (
                      <>
                        {itens.map((produto, index) => (
                          <div className="col-sm-4" key={index}>
                            <div>
                              <IonItem>
                                <IonThumbnail
                                  slot="start"
                                  style={{
                                    height: "120px",
                                    width: "120px",
                                  }}
                                >
                                  <img
                                    alt="Silhouette of mountains"
                                    src={`${urlImagensProdutos}${produto.imagem ? produto.imagem : 'padrao.png'}`}
                                  />
                                </IonThumbnail>
                                <IonLabel>
                                  <h2 style={{ fontWeight: 900 }}>
                                    {produto.nome_produto}
                                  </h2>
                                  <h2
                                    style={{
                                      color: "#C63437",
                                      fontWeight: 900,
                                      marginTop: 10,
                                      fontSize: "17px",
                                    }}
                                  >
                                    R$ {produto.total}
                                  </h2>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "-8%",
                                      }}
                                    >
                                      <div>
                                        <IonButton
                                          color="light"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          onClick={() => {
                                            if (produto.qnt - 1 <= 0) {
                                              confirmarExclusaoItem(
                                                produto.id,
                                                "remover"
                                              );
                                            } else {
                                              carregarAdicionarRemoverItem(
                                                produto.id,
                                                "remover"
                                              );
                                            }
                                          }}
                                        >
                                          -
                                        </IonButton>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        &nbsp;&nbsp;
                                        <span>{produto.qnt}</span>
                                        &nbsp;&nbsp;
                                      </div>
                                      <div>
                                        <IonButton
                                          color="light"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          //disabled={ingrediente.quantidade === ingrediente.limite ? true : false}
                                          onClick={() => {
                                            carregarAdicionarRemoverItem(
                                              produto.id,
                                              "adicionar"
                                            );
                                          }}
                                        >
                                          +
                                        </IonButton>
                                      </div>
                                    </div>
                                  </div>
                                  <h6>
                                    {listaIngredientes(
                                      ingredientes,
                                      produto.id
                                    )}
                                  </h6>
                                </IonLabel>
                              </IonItem>
                              <IonItem>
                                <IonLabel>
                                  <h4>Adicionais</h4>
                                </IonLabel>
                              </IonItem>
                              {adicionais
                                .filter(
                                  (adicional) =>
                                    adicional.item_id === produto.id
                                )
                                .map((adicionalItem, indexAdicional) => (
                                  <>
                                    <IonItem key={indexAdicional}>
                                      <IonLabel>
                                        <h5>
                                          {adicionalItem.nome_ingrediente}
                                        </h5>
                                        <h5>Quantidade:{adicionalItem.qnt}</h5>
                                        <h5>Valor: R$ {adicionalItem.valor}</h5>
                                        <h5>Total: R$ {adicionalItem.total}</h5>
                                      </IonLabel>
                                    </IonItem>
                                  </>
                                ))}
                              <br />
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <IonItem lines="none">
                  <IonLabel>Totais</IonLabel>
                  <IonLabel slot="end">R$ {totalPedido}</IonLabel>
                </IonItem>
                <div className="ion-margin-horizontal">
                  <IonButton
                    onClick={() => {
                      navigate({
                        pathname: "/usuarios/pagamento",
                      });
                    }}
                    color={"danger"}
                    expand="block"
                  >
                    Ir para o pagamento 
                    <IonIcon icon={chevronForwardOutline} slot="end" />
                  </IonButton>
                </div>
              </div>
            ) : (
              <div className="ion-text-center" style={{ marginTop: "40%" }}>
                <Lottie options={defaultOptions} height={150} width={150} />
                <h5 style={{ fontWeight: 500 }}>Seu carrinho está vazio!</h5>
              </div>
            )}
          </>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
