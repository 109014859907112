import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonContent,
  IonThumbnail,
  IonLabel,
  IonPage,
  IonHeader,
  IonToolbar,
} from "@ionic/react";
import {
  urlListarProdutos,
  urlImagensProdutos,
  tokenUsuario,
} from "../../utils/constants";
import MenuSide from "../../components/usuarios/MenuSide";
import LoadingScreen from "../../components/global/LoadingScreen";
import { ModalProduto } from "./ModalProduto";
import { ModalProdutos } from "./ModalProdutos";
import { ModalSucessoCarrinho } from "./ModalSucessoCarrinho";
import axios from "axios";

export default function TelaInicial() {
  const [produtos, setProdutos] = useState([]);
  const [ingredientes, setIngredientes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [produtoId, setProdutoId] = useState(null);
  const [modalProduto, setModalProduto] = useState(false);
  const [modalProdutos, setModalProdutos] = useState(false);
  const [categoriaId, setCategoriaId] = useState("");
  const [nomeCategoria, setNomeCategoria] = useState("");
  const [modalSucessoCarrinho, setModalSucessoCarrinho] = useState(false);
  const [loading, setLoading] = useState(true);

  const consultarProdutos = async () => {
    try {
      const response = await axios.post(urlListarProdutos, {
        token: tokenUsuario,
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const run = async () => {
      const response = await consultarProdutos();
      setProdutos(response.produtos);
      setIngredientes(response.ingredientes);
      setCategorias(response.categorias);
    };
    run();
  }, []);

  const listaIngredientes = (ingredientes, produtoId) => {
    const ingredientesFiltrados = ingredientes.filter(
      (ingrediente) => ingrediente.produto_id === produtoId
    );
    const nomesIngredientes = ingredientesFiltrados.map(
      (ingrediente) => ingrediente.nome
    );
    const lista = nomesIngredientes.join(", ");
    if (lista.length > 0) {
      return `(${lista})`;
    } else {
      return lista;
    }
  };

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <div>
              <IonHeader mode="md">
                <IonToolbar>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    {categorias.map((categoria, index) => (
                      <React.Fragment key={index}>
                        &nbsp;&nbsp;
                        <h6
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                            width: "100%",
                          }}
                          mode="md"
                          value={`categoria${categoria.id}`}
                          onClick={() => {
                            setNomeCategoria(categoria.nome);
                            setCategoriaId(categoria.id);
                            setModalProdutos(true);
                          }}
                        >
                          {categoria.nome}
                        </h6>
                        &nbsp;&nbsp;
                      </React.Fragment>
                    ))}
                  </div>
                </IonToolbar>
              </IonHeader>

              <div className="row">
                {produtos ? (
                  <>
                    {categorias.map((categoria) => (
                      <div key={categoria.id} id={`categoria${categoria.id}`}>
                        <div className="ion-margin-horizontal">
                          <h2>{categoria.nome}</h2>
                        </div>
                        <div className="row">
                          {produtos
                            .filter(
                              (produto) => produto.categoria === categoria.id
                            )
                            .map((produto, index) => (
                              <div className="col-sm-4" key={index}>
                                <div>
                                  <IonItem
                                    button={true}
                                    onClick={() => {
                                      setProdutoId(produto.id);
                                      setModalProduto(true);
                                    }}
                                  >
                                    <IonThumbnail
                                      slot="start"
                                      style={{
                                        height: "120px",
                                        width: "120px",
                                      }}
                                    >
                                      <img
                                        alt={produto.nome}
                                        src={`${urlImagensProdutos}${produto.imagem ? produto.imagem : 'padrao.png'}`}
                                      />
                                    </IonThumbnail>
                                    <IonLabel>
                                      <h2 style={{ fontWeight: 900 }}>
                                        {produto.nome}
                                      </h2>
                                      <p>
                                        {listaIngredientes(
                                          ingredientes,
                                          produto.id
                                        )}
                                      </p>
                                      <h2
                                        style={{
                                          color: "#C63437",
                                          fontWeight: 900,
                                          marginTop: 10,
                                          fontSize: "17px",
                                        }}
                                      >
                                        R$ {produto.valor}
                                      </h2>
                                    </IonLabel>
                                  </IonItem>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {modalProduto ? (
              <ModalProduto
                fecharModal={(value) => setModalProduto(value)}
                idProduto={produtoId}
                sucessoCarrinho={(value) => setModalSucessoCarrinho(value)}
              />
            ) : null}

            {modalProdutos ? (
              <ModalProdutos
                fecharModal={(value) => setModalProdutos(value)}
                idCategoria={categoriaId}
                categoria={nomeCategoria}
                selecionarProduto={(produtoRecebido) => {
                  setProdutoId(produtoRecebido);
                  setModalProduto(true);
                }}
              />
            ) : null}

            {modalSucessoCarrinho ? <ModalSucessoCarrinho /> : null}
          </>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
