import React from "react";
import { IonContent, IonModal, IonButton } from "@ionic/react";

import Lottie from "react-lottie";
import animationData from "./images/sucessAnimation.json";

import { useNavigate } from "react-router-dom";

export const ModalSucessoCarrinho = () => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <IonModal
        isOpen={true}
        mode="ios"
        backdropDismiss="false"
      >
        <IonContent>
          <div className="ion-text-center" style={{ marginTop: "30%" }}>
            <Lottie options={defaultOptions} height={150} width={150} />
          </div>
          <div className="ion-text-center ion-margin">
          <h5 style={{ fontWeight: 500 }}>Item adicionado com sucesso!</h5>
            <br />
            <IonButton
              onclick={() => {
                navigate({ pathname: "/usuarios/carrinho" });
              }}
              color="danger"
              expand="block"
            >
              Ir para o carrinho
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};
