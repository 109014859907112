import React from 'react';
import { IonApp } from '@ionic/react';
import '@ionic/react/css/core.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Usuários

import LoginUsuario from './pages/usuarios/login';
import CadastrarUsuario from './pages/usuarios/cadastrar';
import PedidosUsuario from './pages/usuarios/pedidos';
import PedidoUsuario from './pages/usuarios/pedidos/pedido';
import ContaUsuario from './pages/usuarios/conta';
import CodigoVerificacaoUsuario from './pages/usuarios/codigo_verificacao';
import CarrinhoUsuario from './pages/usuarios/carrinho';
import PagamentoUsuario from './pages/usuarios/pagamento';

import EnderecosCadastroUsuario from './pages/usuarios/enderecos/cadastro';

//Global

import NotFound from './pages/not_found';
import Escolha from './pages/escolha';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './App.css';

export default function App() {

  return (
    <IonApp>
      <Router>
          <Routes>

            <Route path="/" element={<Escolha />} />
            <Route path="/usuarios/login" element={<LoginUsuario />} />
            <Route path="/usuarios/cadastrar" element={<CadastrarUsuario />} />
            <Route path="/usuarios/pedidos" element={<PedidosUsuario />} />
            <Route path="/usuarios/pedido" element={<PedidoUsuario />} />
            <Route path="/usuarios/conta" element={<ContaUsuario />} />            
            <Route path="/usuarios/carrinho" element={<CarrinhoUsuario />} />
            <Route path="/usuarios/pagamento" element={<PagamentoUsuario />} />
            <Route path="/usuarios/codigo_verificacao" element={<CodigoVerificacaoUsuario />} />
            
            <Route path="/usuarios/enderecos/cadastro" element={<EnderecosCadastroUsuario />} />
            
            <Route path="*" element={<NotFound />} />

          </Routes>
        </Router>
    </IonApp>
  );
}