import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonItem,
  IonLabel,
  IonThumbnail,
} from "@ionic/react";
import {
  urlListarProdutos,
  urlImagensProdutos,
  tokenUsuario,
} from "../../utils/constants";
import axios from "axios";
import LoadingScreen from "../../components/global/LoadingScreen";

export const ModalProdutos = ({
  fecharModal,
  idCategoria,
  categoria,
  selecionarProduto,
}) => {
  const [produtos, setProdutos] = useState([]);
  const [ingredientes, setIngredientes] = useState([]);
  const [loading, setLoading] = useState(true);

  const consultarProdutos = async () => {
    try {
      const response = await axios.post(
        `${urlListarProdutos}?categoria=${idCategoria}`,
        {
          token: tokenUsuario,
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const run = async () => {
      const response = await consultarProdutos();
      setProdutos(response.produtos);
      setIngredientes(response.ingredientes);
    };
    run();
  }, []);

  const listaIngredientes = (ingredientes, produtoId) => {
    const ingredientesFiltrados = ingredientes.filter(
      (ingrediente) => ingrediente.produto_id === produtoId
    );
    const nomesIngredientes = ingredientesFiltrados.map(
      (ingrediente) => ingrediente.nome
    );
    const lista = nomesIngredientes.join(", ");
    if (lista.length > 0) {
      return `(${lista})`;
    } else {
      return lista;
    }
  };

  return (
    <>
      <IonModal
        className="modalTermos"
        isOpen={true}
        mode="ios"
        backdropDismiss="false"
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onclick={() => fecharModal(false)}>Voltar</IonButton>
            </IonButtons>
            <IonTitle>{categoria}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {loading ?(
 <LoadingScreen />
          ):(
            <div className="row">
            {produtos.map((produto, index) => (
              <div className="col-sm-4" key={index}>
                <div>
                  <IonItem
                    button={true}
                    onClick={() => {
                      selecionarProduto(produto.id);
                    }}
                  >
                    <IonThumbnail
                      slot="start"
                      style={{
                        height: "120px",
                        width: "120px",
                      }}
                    >
                      <img
                        alt={produto.nome}
                        src={`${urlImagensProdutos}${produto.imagem ? produto.imagem : 'padrao.png'}`}
                      />
                    </IonThumbnail>
                    <IonLabel>
                      <h2 style={{ fontWeight: 900 }}>{produto.nome}</h2>
                      <p>{listaIngredientes(ingredientes, produto.id)}</p>
                      <h2
                        style={{
                          color: "#C63437",
                          fontWeight: 900,
                          marginTop: 10,
                          fontSize: "17px",
                        }}
                      >
                        R$ {produto.valor}
                      </h2>
                    </IonLabel>
                  </IonItem>
                </div>
              </div>
            ))}
          </div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};
