import React from "react";
import { IonSpinner } from "@ionic/react";

export default function LoadingScreen() {
  return (
    <div className="ion-text-center" style={{ marginTop: "10vh" }}>
      <IonSpinner name="crescent" />
    </div>
  );
}
