import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import {
  personOutline,
  person,
  storefront,
  storefrontOutline,
  ticket,
  ticketOutline,
  cart,
  cartOutline,
} from "ionicons/icons";

import { IonRippleEffect } from "@ionic/react";

function MenuSide() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const partesDoCaminho = pathname.split("/");
  const primeiroNomeDoCaminho = partesDoCaminho[2];

  return (
    <div>
      <br />
      <br />
      <br />
      <div
        style={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          bottom: 0,
          background: "#ffffff",
          height: 40,
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          <div className="col-3">
            <div
              onClick={() => navigate({ pathname: "/" })}
              className="ion-activatable ripple-parent rectangle"
              style={{
                color: "#7A9297",
                opacity: 1,
                textAlign: "center",
                margin: 0,
                padding: 0,
                height: 50,
                borderRadius: "2rem",
              }}
            >
              <IonIcon
                style={{ fontSize: 20 }}
                icon={
                  primeiroNomeDoCaminho === undefined
                    ? storefront
                    : storefrontOutline
                }
              ></IonIcon>
              <br />
              <span style={{ fontSize: "14px", fontWeight: 500 }}>
                Produtos
              </span>
              <IonRippleEffect></IonRippleEffect>
            </div>
            <br />
          </div>
          <div className="col-3">
            <div
              onClick={() => navigate({ pathname: "/usuarios/carrinho" })}
              className="ion-activatable ripple-parent rectangle"
              style={{
                color: "#7A9297",
                opacity: 1,
                textAlign: "center",
                margin: 0,
                padding: 0,
                height: 50,
                borderRadius: "2rem",
              }}
            >
              <IonIcon
                style={{ fontSize: 20 }}
                icon={primeiroNomeDoCaminho === "carrinho" ? cart : cartOutline}
              ></IonIcon>
              <br />
              <span style={{ fontSize: "14px", fontWeight: 500 }}>
                Carrinho
              </span>
              <IonRippleEffect></IonRippleEffect>
            </div>
            <br />
          </div>
          <div className="col-3">
            <div
              onClick={() => navigate({ pathname: "/usuarios/pedidos" })}
              className="ion-activatable ripple-parent rectangle"
              style={{
                color: "#7A9297",
                opacity: 1,
                textAlign: "center",
                margin: 0,
                padding: 0,
                height: 50,
                borderRadius: "2rem",
              }}
            >
              <IonIcon
                style={{ fontSize: 20 }}
                icon={
                  primeiroNomeDoCaminho === "pedidos" ? ticket : ticketOutline
                }
              ></IonIcon>
              <br />
              <span style={{ fontSize: "14px", fontWeight: 500 }}>Pedidos</span>
              <IonRippleEffect></IonRippleEffect>
            </div>
            <br />
          </div>
          <div className="col-3">
            <div
              onClick={() => navigate({ pathname: "/usuarios/conta" })}
              className="ion-activatable ripple-parent rectangle"
              style={{
                color: "#7A9297",
                opacity: 1,
                textAlign: "center",
                margin: 0,
                padding: 0,
                height: 50,
                borderRadius: "2rem",
              }}
            >
              <IonIcon
                style={{ fontSize: 20 }}
                icon={
                  primeiroNomeDoCaminho === "conta" ? person : personOutline
                }
              ></IonIcon>
              <br />
              <span style={{ fontSize: "14px", fontWeight: 500 }}>Conta</span>
              <IonRippleEffect></IonRippleEffect>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
export default MenuSide;
