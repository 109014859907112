import React from "react";

export default function StatusPedido({ status }) {
  let statusAgenamento = status;
  let texto = "";
  let cor = "#000000";

  if (statusAgenamento == 1) {
    texto = "Pendente - Em preparação";
    cor = "#16ADAD";
  } else if (statusAgenamento == 2) {
    texto = "Em andamento";
    cor = "#f5c92c";
  } else if (statusAgenamento == 3) {
    texto = "Aguardando confirmação de pagamento";
    cor = "#f5c92c";
  } else if (statusAgenamento == 4) {
    texto = "Finalizado";
    cor = "#16ADAD";
  } else if (statusAgenamento == 5) {
    texto = "Cancelado";
    cor = "#eb445a";
  } else if (statusAgenamento == 6) {
    texto = "Reembolsado";
    cor = "#eb445a";
  } else if (statusAgenamento == 7) {
    texto = "No carrinho";
    cor = "#f5c92c";
  } else {
    texto = "Não encontrado";
    cor = "#000000";
  }

  return (
    <span>
      <h5
        style={{
          marginTop: "7px",
          marginBottom: "5px",
        }}
      >
        <i className="ri-checkbox-blank-circle-fill" style={{ color: cor }}></i>{" "}
        &nbsp; Status: {texto}
      </h5>
    </span>
  );
}
